import React from 'react'
import ServiceSidebar from './ServiceSidebar'
import details1 from '../../assets/images/services/AdvocateForYou500x500.png'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const ServiceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <h3>We will Advocate for You</h3>
                            <p>Our goal is to empower you to seek and obtain resources to fully participate in the activies of every day life. We do this by:</p>

                            
                            <p>- Educate you on your condition and providing you with pathways to seek help.</p>
                            
                            <p>- Advocate directly with government agencies on your individual circumstances to obtain support and services.</p>
                                
                            <p>- Provide advice on the options available to you and help chart a path to a better quality of life.</p>

                            <p>- Support you when times get tough, to ensure that keep working towards making a better life for yourself.</p>

                            <p>- Provide a confidential sounding board for your concerns.</p>
                            <p>We will stand with you as you strive to lead a better fuller life while living with disability.</p>
                            

                           
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContent